import React, { useState, useEffect } from "react";
import { get } from "../../../helpers/api_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useSelector } from "react-redux";
import Modules from "../../../components/Distributor/Customers/Modules/Modules";
import CustomerInfo from "../../../components/Distributor/Customers/CustomerInfo";
import { Spinner, Container } from "reactstrap";
import { useParams } from "react-router-dom";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const CustomerDetails = () => {
  document.title = "Customer Details | PentestBX";

  const { render, render2 } = useSelector((state) => ({
    render: state.render.render,
    render2: state.render.render2,
  }));

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [renderValue, setRenderValue] = useState(false);

  //meta title

  const [userInfo, setUserInfo] = useState();
  const [applianceData, setApplianceData] = useState();
  const [modules, setModules] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModule, setIsLoadingModule] = useState(false);

  const { id } = useParams();

  const renderFunc = () => {
    setRenderValue(!renderValue);
  };

  const getAppliance = async () => {
    paceFunction(true);
    try {
      let result = await get(`/distributor/customer/endpoint?id=${id}`);
      if (result?.success) {
        setApplianceData(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  const getCustomerDetails = async () => {
    paceFunction(true);
    try {
      let result = await get(`/distributor/customer?id=${id}`);
      // if (result?.data[0]?.is_endpoint) getAppliance();
      setUserInfo(result?.data[0]);
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  const getCustomerModules = async () => {
    paceFunction(true);
    try {
      let result = await get(`/distributor/customer/module?id=${id}`);
      if (result?.success) {
        setModules(result?.data?.modules);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoadingModule(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCustomerDetails();
  }, [renderValue]);

  useEffect(() => {
    setIsLoadingModule(true);
    getCustomerModules();
  }, [render]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Customer Details"
            breadcrumbItem="Customer Details"
          />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && userInfo && (
            <CustomerInfo
              userInfo={userInfo}
              applianceData={applianceData}
              userId={id}
              modules={modules}
              renderFunc={renderFunc}
            />
          )}
          {!isLoadingModule && modules && <Modules modules={modules} />}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomerDetails;
