import React, { useRef, useState } from "react";
import Vulnerabilities from "./Vulnerabilities";
import SplineArea from "./Graphics/SplineArea";
import LineGraphic from "./Graphics/LineGraphic";
import DonutChart from "./Graphics/DonutChart";
import {
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { post } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { useHistory } from "react-router-dom";
import WebApplicationPdfReport from "./Pdf/WebApplicationPdfReport";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import { put } from "../../helpers/api_helper";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";
import ScanInfoModal from "./ScanInfoModal";

const WebApplication = ({ assets, moduleID }) => {
  const router = useHistory();

  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [btnDanger1, setBtnDanger1] = useState(false);

  const [addLoading, set_addLoading] = useState(false);

  const { isDemoAccount } = useSelector((state) => ({
    isDemoAccount: state.custom.isDemoAccount,
  }));

  const [arrayChangeValue, setArrayChangeValue] = useState(0);
  const [dataChangeValue, setDataChangeValue] = useState(0);
  const [updated_at, set_updated_at] = useState(assets[0]?.scans[0]?.date);
  const [assetID, setAssetID] = useState(assets[0]?.id);
  const [pdfLoading, set_pdfLoading] = useState(false);
  const [assetName, set_assetName] = useState(assets[0]?.address);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectDisabled, setSelectDisabled] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);

  const [pdfData, setPdfData] = useState();
  const buttonRef = useRef(null);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const onChangeApplications = (data) => {
    let itemData = JSON.parse(data);
    setDataChangeValue(itemData?.index);
    setAssetID(itemData?.id);
    set_assetName(itemData?.address);
    set_updated_at(assets[itemData.index]?.scans[0]?.date);
    setArrayChangeValue(0);
    setSelectedValue("");
  };

  const onChangeDate = (data) => {
    let itemData = JSON.parse(data);
    set_updated_at(itemData?.date);
    setArrayChangeValue(itemData?.index);
  };

  const pdfExportFunc = async (type) => {
    paceFunction(true);
    set_pdfLoading(true);
    let data = {
      id: assetID,
      date: updated_at,
      type,
    };
    try {
      let result = await post("/customer/module/web-app/vulns-export", data);
      if (result?.success) {
        if (type === "xlsx") {
          window.open(result?.data?.report_url, "_blank");
          paceFunction(false);
          set_pdfLoading(false);
        } else {
          setPdfData(result?.data);
          // setDonutData([2, 5]);
          // setTimeout(() => {
          //   // handleConvertHtmlToImage();
          // }, 3000);
          setTimeout(() => {
            setTimeout(() => {
              buttonRef.current.click();
              set_pdfLoading(false);
              paceFunction(false);
              setTimeout(() => {
                setPdfData();
              }, 1000);
            }, 2000);
          }, 6000);
        }
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
      set_pdfLoading(false);
      paceFunction(false);
    }
  };

  const startScanNow = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      module_id: moduleID,
      type: "custom",
      assets: [assetID],
    };
    try {
      let result = await put("/customer/settings/module/task_calendar", data);
      if (result?.success) {
        toastSuccess(result?.message || "Scan Added", 2000);
        tog_standard();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* {pdfData && (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <WebApplicationPdfReport data={pdfData} />
        </PDFViewer>
      )} */}
      <ConfirmModal
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        targetFunction={startScanNow}
        text="Are you sure you want to start scan now?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Scan Info Modal */}
      {assets[dataChangeValue]?.is_scan && (
        <ScanInfoModal
          modal_standard={modal_standard2}
          tog_standard={tog_standard2}
          setmodal_standard={setmodal_standard2}
          data={assets[dataChangeValue]?.scan_detail}
        />
      )}
      <div className="row">
        <div className="col-xl-6 mb-xl-0 mb-3 ">
          <div className="special-card">
            <CardHeader>
              <h4 className="card-title mb-0">
                Application Vulnerability Trends
              </h4>
            </CardHeader>
            <CardBody>
              <SplineArea
                data={assets[dataChangeValue]?.scans
                  ?.slice()
                  ?.sort((a, b) => new Date(a.date) - new Date(b.date))}
                dateFormat={assets[dataChangeValue]?.date_format}
              />
            </CardBody>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="special-card">
            <CardHeader>
              <h4 className="card-title mb-0">Summary of Vulnerability </h4>
            </CardHeader>
            <CardBody>
              <div className="row align-items-center ">
                <div className="col-xl-5">
                  <DonutChart
                    data={assets[dataChangeValue]?.scans}
                    arrayChangeValue={arrayChangeValue}
                  />
                </div>
                <div className="col-sm align-self-center">
                  <div className="mt-4 mt-sm-0">
                    <div>
                      <div className="d-flex justify-content-center">
                        <div
                          className="btn-group "
                          role="group"
                          aria-label="Button group with nested dropdown"
                        >
                          <button
                            type="button"
                            onClick={() => router.push("/report-compare")}
                            className="btn btn-primary w-sm "
                            disabled={selectDisabled}
                          >
                            <i className="mdi mdi-file-compare d-block font-size-20" />
                            Compare Report
                          </button>
                          {assets[dataChangeValue]?.is_scan ? (
                            <button
                              onClick={tog_standard2}
                              type="button"
                              className="btn btn-info w-xs"
                              disabled={selectDisabled}
                            >
                              <i className="mdi mdi-cube-scan d-block font-size-20" />
                              Scan Info
                            </button>
                          ) : (
                            <button
                              onClick={tog_standard}
                              type="button"
                              className="btn btn-primary w-xs"
                              disabled={selectDisabled}
                            >
                              <i className="mdi mdi-axis-z-rotate-clockwise d-block font-size-20" />
                              Scan Now
                            </button>
                          )}

                          <div className="btn-group " role="group">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                disabled={selectDisabled}
                                type="button"
                                color="primary"
                              >
                                {pdfLoading ? (
                                  <i
                                    style={{ marginBottom: "10px" }}
                                    className="bx bx-loader bx-spin d-block font-size-20"
                                    id="gpbicon"
                                  />
                                ) : (
                                  <i className="mdi mdi-chevron-down d-block font-size-20" />
                                )}
                                Export{" "}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => pdfExportFunc("pdf")}
                                >
                                  PDF Export
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => pdfExportFunc("xlsx")}
                                >
                                  XLSX Export
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label>Applications</label>
                        <select
                          disabled={selectDisabled}
                          onChange={(e) => {
                            onChangeApplications(e.target.value);
                          }}
                          className="form-select"
                          id="scan_date"
                        >
                          {assets?.map((item, index) => {
                            let newItem = { ...item, index };
                            return (
                              <option
                                key={index}
                                value={JSON.stringify(newItem)}
                              >
                                {item?.address}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label>History</label>
                        <select
                          disabled={selectDisabled}
                          onChange={(e) => {
                            onChangeDate(e.target.value);
                            setSelectedValue(e.target.value);
                          }}
                          className="form-select"
                          id="scan_date"
                          value={selectedValue}
                        >
                          {assets[dataChangeValue]?.scans
                            ?.sort(
                              (a, b) => new Date(b.date) - new Date(a.date)
                            )
                            ?.map((item, index) => {
                              let newItem = { ...item, index };
                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify(newItem)}
                                >
                                  {item?.created_at}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <label>Score</label>
                      <div
                        style={{ height: "125px" }}
                        className={`bg-hexagon-${
                          assets[dataChangeValue]?.scans[arrayChangeValue]
                            ?.score <= 0.8
                            ? "a"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 1.5
                            ? "b"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 2.4
                            ? "c"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 3.5
                            ? "d"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 4
                            ? "e"
                            : "g"
                        }   pt-3  `}
                      >
                        <div className="row align-items-center justify-content-center mt-1  ">
                          <div className="hexagon-small align-items-center   justify-content-center d-flex ">
                            <span>
                              {assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 0.8
                                ? "A"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 1.5
                                ? "B"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 2.4
                                ? "C"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 3.5
                                ? "D"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 4
                                ? "E"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <LineGraphic
                        data={assets[dataChangeValue]?.scans
                          ?.slice()
                          ?.sort((a, b) => new Date(a.date) - new Date(b.date))}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </div>
        </div>
        <div className="col-md-12 mt-5 ">
          <Vulnerabilities
            updated_at={updated_at}
            assetID={assetID}
            assetName={assetName}
            moduleID={moduleID}
            setSelectDisabled={(e) => setSelectDisabled(e)}
          />
        </div>
      </div>
      {pdfData && (
        <div className="">
          <PDFDownloadLink
            document={
              <WebApplicationPdfReport
                data={pdfData}
                isDemoAccount={isDemoAccount}
              />
            }
            fileName={
              pdfData
                ? `${pdfData?.report_date}-Web_Application_Report.pdf`
                : "Web_Application_Report.pdf"
            }
          >
            {pdfData && <button ref={buttonRef}></button>}
          </PDFDownloadLink>
        </div>
      )}
    </React.Fragment>
  );
};

export default WebApplication;
