import React from "react";
import { remainingDaysCalculation } from "../../../../common/utils/commonFunctions";
import classNames from "classnames";

const RemainingDay = ({ day, dateFormat }) => {
  let remainingDay = remainingDaysCalculation(day, dateFormat);
  if (remainingDay <= 30) {
    return (
      <div
        style={{ minHeight: "300px" }}
        className={classNames("special-card mt-4", {
          "bg-critical-warning": remainingDay <= 7,
          "bg-danger": remainingDay <= 14,
          "bg-warning": remainingDay <= 30,
        })}
      >
        <div className="d-flex flex-column justify-content-center">
          <i
            style={{ fontSize: "120px" }}
            className="mdi mdi-alert-box text-white text-center"
          />
          <span
            style={{ fontSize: "40px" }}
            className="text-white text-center fw-bold"
          >
            Last {remainingDay} {remainingDay > 1 ? "Days" : "Day"}
          </span>
        </div>
      </div>
    );
  } else return null;
};

export default RemainingDay;
