import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";
import { get } from "../../../../helpers/api_helper";
import ChildTabs from "./ChildTabs";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function IpsTable({ data, entryValue }) {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();
  // Others
  const [rowId, setRowId] = useState([]);
  const [vulnsDetails, setVulnsDetails] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "first_name",
      },
      {
        Header: "IP",
        accessor: (row) => row,
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "company_name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  let rowData = [];

  const getVulnsDetails = async (row) => {
    paceFunction(true);
    let tempVulnsDetails = vulnsDetails;
    let isCheck = tempVulnsDetails.some((item) => item.id === row.original);
    if (!isCheck) {
      try {
        let result = await get(
          `/customer/module/cyberwatch/asm?asset=${row.original}`
        );
        if (result.success) {
          tempVulnsDetails = [
            ...tempVulnsDetails,
            { id: row.original, data: result.data },
          ];
          setVulnsDetails(tempVulnsDetails);
          rowIdFunc(row.id);
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      rowIdFunc(row.id);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <div
      //  style={{ maxHeight: "1200px", overflow: "overlay" }}
      >
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th className="">{headerGroup.headers[0].Header}</th>
                <th className="col-12">{headerGroup.headers[1].Header} </th>
              </tr>
            ))}
          </thead>

          {data?.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
                prepareRow(row);
                rowData.push(row.original);
                return (
                  <React.Fragment key={index}>
                    <tr
                      className={`cursor-pointer ${
                        rowId.some((item) => item === row.id)
                          ? "bg-soft-primary"
                          : ""
                      }`}
                      onClick={() => {
                        getVulnsDetails(row);
                      }}
                      style={{ width: "100%", cursor: "pointer" }}
                      {...row.getRowProps()}
                    >
                      <td className="">
                        {rowId.some((item) => item === row.id) ? (
                          <i className="fa fa-chevron-down" />
                        ) : (
                          <i className="fa fa-chevron-right" />
                        )}
                      </td>
                      <td className="col-12">{row.cells[1].value}</td>

                      {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                    </tr>
                    <AnimatePresence>
                      {rowId.some((item) => item === row.id) && (
                        <motion.tr
                          initial={{ y: -100, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: 0, opacity: 0 }}
                          transition={{ duration: 0.4 }}
                        >
                          {vulnsDetails.map((vuln, index) => {
                            if (vuln?.id === row.original) {
                              return (
                                <td key={index}>
                                  <div>
                                    <Table className="table mb-0">
                                      <tbody
                                        // style={{ backgroundColor: "#f7f7f7" }}
                                        border={1}
                                      >
                                        <tr>
                                          <td className="fw-semibold">
                                            <div className="special-card  ">
                                              <div className="d-flex align-items-center">
                                                <i className="mdi mdi-ip-network fs-2 text-primary me-2  " />
                                                <h3 className="mt-1 ">
                                                  {vuln?.id}
                                                </h3>
                                              </div>
                                              <div className="row my-2 ">
                                                {Object.keys(
                                                  vuln?.data?.data?.geolocation
                                                )?.length > 0 && (
                                                  <div className="d-flex col-xl-2 col-md-3 col-6  border-end border-1 border-secondary pe-2 ">
                                                    <div className="">
                                                      <i className="mdi mdi-flag fs-3 text-primary  " />
                                                    </div>
                                                    <div className="ms-lg-3 d-flex  flex-column  ">
                                                      <span className="fs-4 fw-bold ">
                                                        COUNTRY
                                                      </span>
                                                      <span className="fw-normal  ">
                                                        {
                                                          vuln?.data?.data
                                                            ?.geolocation?.data
                                                            ?.name
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                                {Object.keys(
                                                  vuln?.data?.data?.score
                                                )?.length > 0 && (
                                                  <div className="d-flex col-xl-2 col-md-3 col-6   border-end border-1 border-secondary px-2 ">
                                                    <div className="">
                                                      <i className="mdi mdi-creation fs-3 text-primary  " />
                                                    </div>
                                                    <div className="ms-lg-3 d-flex  flex-column  ">
                                                      <span className="fs-4 fw-bold ">
                                                        SCORE
                                                      </span>
                                                      <span className="fw-normal  ">
                                                        {
                                                          vuln?.data?.data
                                                            ?.score?.data
                                                            ?.normalized_ip_score
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                                {Object.keys(
                                                  vuln?.data?.data?.score
                                                )?.length > 0 && (
                                                  <div className="d-flex col-xl-2 col-md-3 col-6  border-end border-1 border-secondary px-2  ">
                                                    <div className="">
                                                      <i className="mdi mdi-alert-octagon fs-3 text-primary  " />
                                                    </div>
                                                    <div className="ms-lg-3 d-flex  flex-column  ">
                                                      <span className="fs-4 fw-bold ">
                                                        ATTACK SURFACE
                                                      </span>
                                                      <span className="fw-normal  ">
                                                        {
                                                          vuln?.data?.data
                                                            ?.score?.data
                                                            ?.ip_score_detailed
                                                            ?.attack_surface
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              {Object.keys(
                                                vuln?.data?.data?.geolocation
                                              )?.length < 1 &&
                                                Object.keys(
                                                  vuln?.data?.data?.score
                                                )?.length < 1 && (
                                                  <h3>
                                                    <span className="mdi mdi-information-off text-primary me-1" />
                                                    There is no info data{" "}
                                                  </h3>
                                                )}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <ChildTabs
                                              datas={vuln?.data?.data}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <div />
                                  </div>
                                </td>
                              );
                            }
                          })}
                        </motion.tr>
                      )}
                    </AnimatePresence>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {data?.length < 1 && (
          <span
            style={{ width: "100%" }}
            className="d-flex justify-content-center "
          >
            No data available in table
          </span>
        )}
      </div>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default IpsTable;
