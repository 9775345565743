import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { post } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import { motion, AnimatePresence } from "framer-motion";
import {
  getBrightness,
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const SubscribeVendorProductModal = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  allTags,
  type,
  name,
  vendor_name,
  getList,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [tags, set_tags] = useState(allTags);
  const [selectedTags, set_selectedTags] = useState([]);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const [addLoading, set_addLoading] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const subscribeVendorProduct = async () => {
    paceFunction(true);
    set_addLoading(true);

    let data;

    if (type === "vendor") {
      data = {
        name,
        type,
      };
    } else {
      data = {
        name,
        type,
        vendor_name,
      };
    }
    let tagIds = selectedTags.map((item) => item?.id);
    if (tagIds.length > 0) {
      data.tag_id = tagIds;
    }

    try {
      let result = await post(
        "/customer/module/cyberwatch/ti/cves/subscriptions",
        data
      );
      if (result?.success) {
        toastSuccess(result?.message || "Subscribed");
        tog_standard5();
        tog_standard();
        getList();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    if (modal_standard) {
      set_tags(allTags);
      set_selectedTags([]);
    }
  }, [modal_standard]);

  const handleChange = (tag) => {
    if (tag) {
      let selectedTag = JSON.parse(tag);
      // Selected Tags
      let tempArraySelectedTags = [...selectedTags];
      let checkSelectedTags = selectedTags.some(
        (item) => item?.id === selectedTag?.id
      );
      if (checkSelectedTags) {
        let filterFunc = selectedTags.filter(
          (item) => item?.id !== selectedTag?.id
        );
        set_selectedTags(filterFunc);
      } else {
        tempArraySelectedTags.push(selectedTag);
        set_selectedTags(tempArraySelectedTags);
      }
      // Tags
      let tempArrayTags = [...tags];
      let checkTags = tags.some((item) => item?.id === selectedTag?.id);
      if (checkTags) {
        let filterFunc = tags.filter((item) => item?.id !== selectedTag?.id);
        set_tags(filterFunc);
      } else {
        tempArrayTags.push(selectedTag);
        set_tags(tempArrayTags);
      }
    }
  };

  return (
    <React.Fragment>
      {/* Subscribe */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={subscribeVendorProduct}
        text="Are you sure you want to subscribe?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            If you want to add tag, you can choose or you can leave it blank
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            className="swal2-html-container"
            id="swal2-html-container"
            style={{ display: "block" }}
          >
            <div>
              <div className="d-flex justify-content-between align-items-center ">
                <label>Selected Tags</label>
                <div>
                  <button
                    onClick={() => {
                      set_selectedTags(allTags);
                      set_tags([]);
                    }}
                    className="btn btn-outline-success btn-sm  "
                  >
                    Select All
                  </button>
                  <button
                    onClick={() => {
                      set_selectedTags([]);
                      set_tags(allTags);
                    }}
                    className="btn btn-outline-danger btn-sm  "
                  >
                    Remove All
                  </button>
                </div>
              </div>
              <div
                className="border border-1 border-primary d-flex flex-wrap gap-1   p-1 rounded-3   "
                style={{
                  maxHeight: "280px",
                  overflowY: "overlay",
                  minHeight: "30px",
                }}
              >
                <AnimatePresence>
                  {selectedTags?.map((selectedTag, selectedTagIndex) => (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      key={selectedTagIndex}
                    >
                      <div
                        className="badge p-2 "
                        style={{
                          color: `${
                            getBrightness(selectedTag?.color) < 128
                              ? "white"
                              : "black"
                          }`,
                          backgroundColor: selectedTag?.color,
                        }}
                      >
                        {selectedTag?.name}
                        <span
                          className={`float-end remove-tag  badge-danger ms-1 private-tag
                          `}
                          onClick={() =>
                            handleChange(JSON.stringify(selectedTag))
                          }
                        >
                          X
                        </span>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            </div>
            <br />
            <div className="input-group">
              <span className="input-group-text">Tags</span>
              <select
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                name="new_group_severity"
                className="form-select"
                value=""
              >
                <option value="" />
                {tags?.map((tag, tagIndex) => (
                  <option
                    key={tagIndex}
                    className="text-info"
                    value={JSON.stringify(tag)}
                  >
                    {tag?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary text-truncate"
          >
            <i className="mdi mdi-plus me-1" /> Subscribe
          </button>
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default SubscribeVendorProductModal;
