import React, { useState } from "react";
import DashedLine from "./Graphics/DashedLine";
import DonutChart from "./Graphics/DonutChart";
import LineGraphic from "./Graphics/LineGraphic";
import Vulnerabilities from "./Vulnerabilities";
import MailboxThreatsSuggestions from "./MailboxThreatsSuggestions";
import { CardBody, CardHeader } from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";
import { put } from "../../helpers/api_helper";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import ScanInfoModal from "./ScanInfoModal";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const MailboxThreats = ({ assets, moduleID }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const router = useHistory();

  const [arrayChangeValue, setArrayChangeValue] = useState(0);
  const [dataChangeValue, setDataChangeValue] = useState(0);
  const [updated_at, set_updated_at] = useState(
    assets[0]?.scans[0]?.truncated_date
  );
  const [assetID, setAssetID] = useState(assets[0]?.id);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectDisabled, setSelectDisabled] = useState(false);

  const [addLoading, set_addLoading] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standard2() {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  }

  function tog_standard3() {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  }

  const onChangeApplications = (data) => {
    let itemData = JSON.parse(data);
    setDataChangeValue(itemData?.index);
    setAssetID(itemData?.id);
    set_updated_at(assets[itemData.index]?.scans[0]?.truncated_date);
    setArrayChangeValue(0);
    setSelectedValue("");
  };

  const onChangeDate = (data) => {
    let itemData = JSON.parse(data);
    set_updated_at(itemData?.truncated_date);
    setArrayChangeValue(itemData.index);
  };

  const extractValues = () => {
    const values = [];
    if (assets) {
      values.push(assets[dataChangeValue]?.scans[arrayChangeValue]?.insecure);
      values.push(assets[dataChangeValue]?.scans[arrayChangeValue]?.secure);
    }
    return values;
  };

  const toplamDegeriHesapla = (dizi) => {
    const toplam = dizi.reduce((acc, currentValue) => acc + currentValue, 0);
    return toplam;
  };

  const series = extractValues();
  const toplam = toplamDegeriHesapla(series) || 0;

  const startScanNow = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      module_id: moduleID,
      type: "custom",
      assets: [assetID],
    };
    try {
      let result = await put("/customer/settings/module/task_calendar", data);
      if (result?.success) {
        toastSuccess(result?.message || "Scan Added", 2000);
        tog_standard();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };
  return (
    <React.Fragment>
      {/* Suggestions Info Modal */}
      <MailboxThreatsSuggestions
        tog_standard={tog_standard2}
        modal_standard={modal_standard2}
        setmodal_standard={setmodal_standard2}
      />
      <ConfirmModal
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        targetFunction={startScanNow}
        text="Are you sure you want to start scan now?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Scan Info Modal */}
      {assets[dataChangeValue]?.is_scan && (
        <ScanInfoModal
          modal_standard={modal_standard3}
          tog_standard={tog_standard3}
          setmodal_standard={setmodal_standard3}
          data={assets[dataChangeValue]?.scan_detail}
        />
      )}
      <div className="row">
        <div className="col-xl-6 mb-xl-0 mb-3 ">
          <div className="special-card">
            <CardHeader>
              <h4 style={{ paddingTop: "7px" }} className="card-title">
                Trends
              </h4>
            </CardHeader>
            <div>
              <DashedLine
                data={assets[dataChangeValue]?.scans
                  ?.slice()
                  ?.sort(
                    (a, b) =>
                      new Date(a.truncated_date) - new Date(b.truncated_date)
                  )}
                dateFormat={assets[dataChangeValue]?.date_format}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="special-card ">
            <CardHeader>
              <div className="d-flex flex-row justify-content-between align-items-center ">
                <h4 className="card-title mb-0">Overview</h4>
                <button
                  type="button"
                  className="btn btn-success position-relative p-2 avatar-sm me-2    rounded"
                  title="Suggestions"
                  onClick={tog_standard2}
                >
                  <span className="avatar-title bg-transparent d-flex flex-column ">
                    <i className="mdi mdi-information-outline font-size-20"></i>
                  </span>
                  <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </button>
              </div>
            </CardHeader>
            <CardBody>
              <div className="row align-items-center ">
                <div className="col-xl-5">
                  <DonutChart
                    data={assets[dataChangeValue]?.scans}
                    arrayChangeValue={arrayChangeValue}
                  />
                  <div className="row mb-2 ">
                    <label className="col-sm-7 col-form-label justify-content-center d-flex ">
                      Total Emails Sent
                    </label>
                    <label
                      className="col-sm-5 col-form-label text-info justify-content-center d-flex "
                      id="label_total"
                    >
                      {toplam}
                    </label>
                  </div>
                  <div className="row mb-2">
                    <label className="col-sm-7 col-form-label justify-content-center d-flex ">
                      Secure
                    </label>
                    <label
                      className="col-sm-5 col-form-label text-success justify-content-center d-flex "
                      id="label_total"
                    >
                      {assets[dataChangeValue]?.scans[arrayChangeValue]
                        ?.secure || "0"}
                    </label>
                  </div>
                  <div className="row mb-2">
                    <label className="col-sm-7 col-form-label justify-content-center d-flex ">
                      Insecure
                    </label>
                    <label
                      className="col-sm-5 col-form-label text-danger justify-content-center d-flex "
                      id="label_total"
                    >
                      {assets[dataChangeValue]?.scans[arrayChangeValue]
                        ?.insecure || "0"}
                    </label>
                  </div>
                </div>
                <div className="col-sm align-self-center">
                  <div className="mt-4 mt-sm-0">
                    <div>
                      <div className="d-flex justify-content-center">
                        <div
                          className="btn-group "
                          role="group"
                          aria-label="Button group with nested dropdown"
                        >
                          <button
                            type="button"
                            onClick={() => router.push("/report-compare")}
                            className="btn btn-primary w-sm "
                            disabled={selectDisabled}
                          >
                            <i className="mdi mdi-file-compare d-block font-size-20" />
                            Compare Report
                          </button>
                          {assets[dataChangeValue]?.is_scan ? (
                            <button
                              onClick={tog_standard3}
                              type="button"
                              className="btn btn-info w-xs"
                              disabled={selectDisabled}
                            >
                              <i className="mdi mdi-cube-scan d-block font-size-20" />
                              Scan Info
                            </button>
                          ) : (
                            <button
                              onClick={tog_standard}
                              type="button"
                              className="btn btn-primary w-xs"
                              disabled={selectDisabled}
                            >
                              <i className="mdi mdi-axis-z-rotate-clockwise d-block font-size-20" />
                              Scan Now
                            </button>
                          )}
                          {/* 
                          <div className="btn-group " role="group">
                            <UncontrolledDropdown>
                              <DropdownToggle type="button" color="primary">
                                {pdfLoading ? (
                                  <i
                                    className="bx bx-loader bx-spin d-block font-size-20"
                                    id="gpbicon"
                                  />
                                ) : (
                                  <i className="mdi mdi-chevron-down d-block font-size-20" />
                                )}
                                Export{" "}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => pdfExportFunc("pdf")}
                                >
                                  PDF Export
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => pdfExportFunc("xlsx")}
                                >
                                  XLSX Export
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div> */}
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label>Address</label>
                        <select
                          disabled={selectDisabled}
                          onChange={(e) => onChangeApplications(e.target.value)}
                          className="form-select"
                          id="scan_date"
                        >
                          {assets?.map((item, index) => {
                            let newItem = { ...item, index };
                            return (
                              <option
                                key={index}
                                value={JSON.stringify(newItem)}
                              >
                                {item?.address}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label>History</label>
                        <select
                          disabled={selectDisabled}
                          onChange={(e) => {
                            onChangeDate(e.target.value);
                            setSelectedValue(e.target.value);
                          }}
                          className="form-select"
                          id="scan_date"
                          value={selectedValue}
                        >
                          {assets[dataChangeValue]?.scans
                            ?.sort(
                              (a, b) =>
                                new Date(b.truncated_date) -
                                new Date(a.truncated_date)
                            )
                            ?.map((item, index) => {
                              let newItem = { ...item, index };
                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify(newItem)}
                                >
                                  {item?.created_at}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <label>Score</label>
                      <div
                        style={{ height: "125px" }}
                        className={`bg-hexagon-${
                          assets[dataChangeValue]?.scans[arrayChangeValue]
                            ?.score <= 0.8
                            ? "a"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 1.5
                            ? "b"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 2.4
                            ? "c"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 3.5
                            ? "d"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 4
                            ? "e"
                            : "g"
                        }   pt-3  `}
                      >
                        <div className="row align-items-center justify-content-center mt-1  ">
                          <div className="hexagon-small align-items-center   justify-content-center d-flex ">
                            <span>
                              {assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 0.8
                                ? "A"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 1.5
                                ? "B"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 2.4
                                ? "C"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 3.5
                                ? "D"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 4
                                ? "E"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <LineGraphic
                        data={assets[dataChangeValue]?.scans
                          ?.slice()
                          ?.sort(
                            (a, b) =>
                              new Date(a.truncated_date) -
                              new Date(b.truncated_date)
                          )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </div>
        </div>
        <div className="col-md-12 mt-5 ">
          <Vulnerabilities
            updated_at={updated_at}
            assetID={assetID}
            setSelectDisabled={(e) => setSelectDisabled(e)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MailboxThreats;
