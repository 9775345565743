import React, { useEffect, useRef, useState } from "react";
import { Badge, Col, Modal, Row } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import { motion, AnimatePresence } from "framer-motion";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";

const ScanNowAudits = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  moduleID,
  allAssets,
}) => {
  const { paceFunction } = paceFunctionComponent();
  const { errorToastFunction } = errorFunctionComponent();

  const [addLoading, set_addLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [type, set_type] = useState("all");

  const [assets, setAssets] = useState(allAssets);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [allFilteredAssets, setAllFilteredAssets] = useState([]);
  const [searchInputValue, set_searchInputValue] = useState("");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  let hostGroups = Array.from(
    new Map(
      allAssets.map((item) => [
        item.group_id,
        { group_name: item.group_name, group_id: item.group_id },
      ])
    ).values()
  );

  const findMissingAssets = (allAssets, assets) => {
    const assetAddresses = assets?.map((asset) => asset?.address);

    const missingAssets = allAssets?.filter(
      (asset) => !assetAddresses?.includes(asset?.address)
    );

    return missingAssets;
  };

  useEffect(() => {
    const missingAssets = findMissingAssets(allAssets, assets);
    setFilteredAssets(missingAssets);
    setAllFilteredAssets(missingAssets);
  }, [assets]);

  useEffect(() => {
    if (!modal_standard) {
      set_type("all");
      setAssets(allAssets);
    }
  }, [modal_standard]);

  useEffect(() => {
    setAssets(allAssets);
  }, [allAssets]);

  const handleInputFocus = () => {
    if (!isDropdownOpen && !isOpen) {
      setIsDropdownOpen(true);
      setIsOpen(true);
    }
  };

  const handleInputBlur = () => {
    if (isDropdownOpen && isOpen) {
      setTimeout(() => {
        setIsDropdownOpen(false);
        setIsOpen(false);
        set_searchInputValue("");
      }, 100);
    }
  };

  const handleOutsideClick = (event) => {
    if (!event.target.classList.contains("exclude-span")) {
      inputRef.current.focus();
    }
  };

  const addHostAddress = (item) => {
    let tempArray = [...assets, item];
    setAssets(tempArray);
    set_searchInputValue("");
  };

  const filterAddressesByNumber = (number) => {
    const filteredAddresses = allFilteredAssets?.filter((address) => {
      return address?.address?.includes(number.toString());
    });

    return filteredAddresses;
  };

  const handleChangeSearch = (e) => {
    set_searchInputValue(e.target.value);
    let result = filterAddressesByNumber(e.target.value);
    setFilteredAssets(result);
  };

  const handleRemoveAddress = async (tag, index) => {
    const updatedTags = [...assets];
    updatedTags.splice(index, 1);
    setAssets(updatedTags);
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const addNewTask = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data;
    if (type === "all") {
      data = {
        module_id: moduleID,
        type: "all",
      };
    } else {
      let customAssets = assets.map((item) => item.id);
      data = {
        module_id: moduleID,
        type: "custom",
        assets: customAssets,
      };
    }
    try {
      let result = await put("/customer/settings/module/task_calendar", data);
      if (result?.success) {
        toastSuccess(result?.message || "Task Added", 2000);
        tog_standard();
        tog_standard5();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  const filteredByHostGroup = (group) => {
    let filteredHostsByGroup = allAssets.filter(
      (item) => item.group_id === group
    );
    setAssets(filteredHostsByGroup);
  };

  return (
    <React.Fragment>
      {/* Add Task */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addNewTask}
        text="Are you sure you want to add task?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Scan Now
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="col-12">
            <div className="mb-3"></div>
          </div>
          <div className="col-12 mb-2">
            <select
              className="form-select"
              name="web_task_type"
              style={{ border: "1px solid #3fa0da" }}
              onChange={(e) => set_type(e.target.value)}
            >
              <option value="all">ALL</option>
              <option value="custom">CUSTOM</option>
            </select>
          </div>{" "}
          {type === "custom" && (
            <div>
              <span className="font-size-14 fw-bold">Groups</span>
              <div className="d-flex flex-wrap">
                {hostGroups.map((groupItem) => (
                  <span
                    className="badge bg-primary me-2 cursor-pointer"
                    key={groupItem.group_id}
                    onClick={() => filteredByHostGroup(groupItem.group_id)}
                  >
                    {groupItem.group_name}
                  </span>
                ))}
              </div>
            </div>
          )}
          {type === "custom" && (
            <div className="form-group mb-3 mt-1">
              <div className="d-flex justify-content-between align-items-center ">
                <label>Assets ({assets.length})</label>
                <div>
                  <button
                    type="button"
                    data-tag-select="ip_addressesstags"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                      setAssets(allAssets);
                    }}
                  >
                    Select All
                  </button>
                  <button
                    type="button"
                    data-tag-unselect="ip_addressesstags"
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => {
                      setAssets([]);
                    }}
                  >
                    Unselect All
                  </button>
                </div>
              </div>
              <div
                className="border border-1 border-primary d-flex flex-wrap gap-1   p-1 rounded-3   "
                style={{
                  maxHeight: "280px",
                  overflowY: "overlay",
                  minHeight: "100px",
                }}
                onClick={handleOutsideClick}
              >
                <AnimatePresence>
                  {assets?.map((tag, index) => (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      key={tag?.address}
                      className="exclude-span  "
                    >
                      <Badge
                        style={{ maxHeight: "25px" }}
                        className={`fs-6 w-100   tag  bg-${
                          tag?.is_private === false ? "danger" : "primary"
                        } mr-1`}
                        title={` ${
                          tag?.is_private === true ? "Private" : "Public"
                        } ${tag.address}`}
                      >
                        {tag?.is_private === true ? (
                          <i className="float-start mdi mdi-ip-network-outline address-white me-1" />
                        ) : (
                          <i className="float-start mdi mdi-weather-cloudy-alert address-white me-1" />
                        )}

                        <span className="mx-1">{tag?.address}</span>
                        <span
                          className={`float-end exclude-span remove-tag  badge-danger me-1   ${
                            tag?.is_private === false
                              ? "public-tag"
                              : "private-tag"
                          }`}
                          onClick={() => handleRemoveAddress(tag, index)}
                        >
                          X
                        </span>
                      </Badge>
                    </motion.div>
                  ))}
                </AnimatePresence>
                <div className="outside-area">
                  <input
                    style={{ border: "none", outline: "none" }}
                    className="exclude-span input-dark"
                    type="text"
                    value={searchInputValue}
                    ref={inputRef}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onChange={handleChangeSearch}
                  />
                </div>
              </div>
              <AnimatePresence>
                {isDropdownOpen && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`dropdown-host ${isOpen ? "open" : ""}`}
                    ref={dropdownRef}
                  >
                    <ul
                      style={{
                        top: "-15px",
                        maxHeight: "257px",
                        overflowY: "overlay",
                      }}
                      className="options-host"
                    >
                      {filteredAssets?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => addHostAddress(item)}
                          className="fs-6"
                        >
                          {item?.address}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-light me-2"
                  onClick={tog_standard}
                >
                  Close
                </button>
                <button
                  onClick={tog_standard5}
                  type="button"
                  className="btn btn-success save-event"
                >
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ScanNowAudits;
