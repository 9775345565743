import React, { useEffect, useRef, useState } from "react";
import {
  CardHeader,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Badge,
} from "reactstrap";
import "./Tag.css";
import { motion, AnimatePresence } from "framer-motion";
import { del, get, put, patch } from "../../../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import HostsTickets from "./HostsTickets";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";
import HostsAddTask from "./HostsAddTask";

const Hosts = ({ datas }) => {
  const { errorFunction, errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [addPublicLoading, setAddPublicLoading] = useState(false);
  const [addPrivateLoading, setAddPrivateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);

  const [btnprimary1, setBtnprimary1] = useState(false);
  const [viewTickets, setViewTickets] = useState(false);
  const [tickets, setTickets] = useState();
  // Assets
  const [hosts, setHosts] = useState();
  const [hostInput, setHostInput] = useState("");
  // Update
  const [is_active, set_is_active] = useState(datas.is_enable);
  const [asset_count, set_asset_count] = useState(datas.asset_count);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standard2() {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  }

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const tog_standard4 = () => {
    setmodal_standard4(!modal_standard4);
    removeBodyCss();
  };
  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const tog_standard6 = () => {
    setmodal_standard6(!modal_standard6);
    removeBodyCss();
  };

  const handleTagInputChange = (e) => {
    setHostInput(e.target.value);
  };

  const handleRemoveTag = async (tag, index) => {
    paceFunction(true);
    const updatedTags = [...hosts];
    updatedTags.splice(index, 1);

    try {
      let result = await del(
        `/admin/customer/module/asset?id=${datas?.user_id}&module_id=${datas?.id}&asset_id=${tag?.id}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Hosts Removed");
        setHosts(updatedTags);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  const getAssets = async () => {
    paceFunction(true);
    try {
      let result = await get(
        `/admin/customer/module/asset?id=${datas?.user_id}&module_id=${datas?.id}`
      );
      if (result?.success) {
        setHosts(result?.data[0]?.assets);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  const getTickets = async () => {
    paceFunction(true);
    try {
      let result = await get(`/admin/customer/module/tickets?id=${datas?.id}`);
      if (result?.success) {
        setTickets(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getAssets();
    getTickets();
  }, []);

  const updateHosts = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    let data = {
      asset_count,
      is_enable: is_active,
      user_id: datas.user_id,
      id: datas.id,
    };
    try {
      let response = await patch("/admin/customer/module", data);
      if (response?.success) {
        toastSuccess(response?.message || "Module Updated");
        tog_standard3();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  const removeModule = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del(
        `/admin/customer/module?id=${datas?.user_id}&module_id=${datas?.id}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Module Removed");
        tog_standard4();
        dispatch({ type: "RENDER_COMPONENT_REQ" });
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  const sendHostsAsset = async (array) => {
    paceFunction(true);
    let data = {
      id: datas.id,
      assets: array,
    };
    try {
      let result = await put("/admin/customer/module/asset", data);
      if (result?.success) {
        toastSuccess(result?.message || "Hosts Added");
        getAssets();
        if (modal_standard5) tog_standard5();
        if (modal_standard6) tog_standard6();
        setHostInput("");
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddPrivateLoading(false);
    setAddPublicLoading(false);
    paceFunction(false);
  };

  const removeHostsAsset = async () => {
    paceFunction(true);
    setDeleteLoading(true);
    try {
      let result = await del(
        `/admin/customer/module/asset?id=${datas?.user_id}&module_id=${datas?.id}&asset_id=2&remove_all=true`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Hosts Removed");
        tog_standard2();
        getAssets();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setDeleteLoading(false);
    paceFunction(false);
  };

  const handleGenerateIPsPrivate = () => {
    setAddPrivateLoading(true);
    let result = addIPtoPrivate(hostInput);
    sendHostsAsset(result);
  };

  const handleGenerateIPsPublic = () => {
    setAddPublicLoading(true);
    let result = addIPtoPublic(hostInput);
    sendHostsAsset(result);
  };

  const parseCIDR = (CIDR) => {
    var beg = CIDR.substr(CIDR, CIDR.indexOf("/"));
    var end = beg;
    var off = (1 << (32 - parseInt(CIDR.substr(CIDR.indexOf("/") + 1)))) - 1;
    var sub = beg.split(".").map(function (a) {
      return parseInt(a);
    });
    var buf = new ArrayBuffer(4);
    var i32 = new Uint32Array(buf);
    i32[0] = (sub[0] << 24) + (sub[1] << 16) + (sub[2] << 8) + sub[3] + off;
    var end = Array.apply([], new Uint8Array(buf)).reverse().join(".");
    //Wildcard ve broadcast i cikar
    var ipParts = beg.split(".");
    beg =
      ipParts[0] +
      "." +
      ipParts[1] +
      "." +
      ipParts[2] +
      "." +
      (parseInt(ipParts[3], 10) + 1);
    var ipParts = end.split(".");
    end =
      ipParts[0] +
      "." +
      ipParts[1] +
      "." +
      ipParts[2] +
      "." +
      (parseInt(ipParts[3], 10) - 1);
    return [beg, end];
  };

  const ipToHex = (ipAddr) => {
    var match = /(\d+\.\d+\.\d+\.\d+)/.exec(ipAddr);
    var output = "";
    var matchText = match[1];
    var ipParts = matchText.split(".");
    var p3 = parseInt(ipParts[3], 10);
    var p3x = p3.toString(16);
    var p2 = parseInt(ipParts[2], 10);
    var p2x = p2.toString(16);
    var p1 = parseInt(ipParts[1], 10);
    var p1x = p1.toString(16);
    var p0 = parseInt(ipParts[0], 10);
    var p0x = p0.toString(16);
    var dec = p3 + p2 * 256 + p1 * 256 * 256 + p0 * 256 * 256 * 256;
    var hex = dec.toString(16);
    function pad2(hex) {
      while (hex.length < 2) {
        hex = "0" + hex;
      }
      return hex;
    }
    function pad8(hex) {
      while (hex.length < 8) {
        hex = "0" + hex;
      }
      return hex;
    }
    hex = "0x" + pad8(hex);
    output +=
      pad2(p0x) +
      "." +
      pad2(p1x) +
      "." +
      pad2(p2x) +
      "." +
      pad2(p3x) +
      " (" +
      hex +
      ")";
    return parseInt(hex);
  };
  const filterIPsWithEndingZero = (ipList) => {
    const filteredList = ipList.filter((ip) => !ip.endsWith(".0"));
    return filteredList;
  };
  const ipRangeToList = (ipv4_1, ipv4_2) => {
    var startIp = ipToHex(ipv4_1),
      endIp = ipToHex(ipv4_2);
    var temp,
      list = [],
      str;
    for (var i = startIp; i <= endIp; i++) {
      temp = i.toString(16);
      str = "";
      if (temp.length == 7) {
        temp = "0" + temp;
      }
      for (var k = temp.length - 1; k >= 0; k -= 2) {
        str = parseInt(temp[k - 1] + "" + temp[k], 16) + "." + str;
      }
      list.push(str.substring(0, str.length - 1));
    }
    return list;
  };

  //IP to cidr notation, new_u, useradd_iptag->inventory
  //________________________________________________________
  const addIPtoPrivate = (listIP) => {
    const ipParts = listIP.split(",");
    let addIPList = [];

    for (const ip of ipParts) {
      if (ip.includes("-")) {
        const range = ip.split("-");
        const rangeList = ipRangeToList(range[0], range[1]);
        addIPList = addIPList.concat(rangeList);
      } else if (ip.includes("/")) {
        const rangeIP = parseCIDR(ip);
        const ipList = ipRangeToList(rangeIP[0], rangeIP[1]);
        const filteredIPs = filterIPsWithEndingZero(ipList);
        addIPList = addIPList.concat(filteredIPs);
      } else {
        addIPList.push(ip);
      }
    }

    const uniqueAddIPList = [...new Set(addIPList)];

    return uniqueAddIPList.map((ip) => ({ address: ip, is_private: true }));
  };

  const addIPtoPublic = (listIP) => {
    const ipParts = listIP.split(",");
    let addIPList = [];

    for (const ip of ipParts) {
      if (ip.includes("-")) {
        const range = ip.split("-");
        const rangeList = ipRangeToList(range[0], range[1]);
        addIPList = addIPList.concat(rangeList);
      } else if (ip.includes("/")) {
        const rangeIP = parseCIDR(ip);
        const ipList = ipRangeToList(rangeIP[0], rangeIP[1]);
        const filteredIPs = filterIPsWithEndingZero(ipList);
        addIPList = addIPList.concat(filteredIPs);
      } else {
        addIPList.push(ip);
      }
    }

    const uniqueAddIPList = [...new Set(addIPList)];

    return uniqueAddIPList.map((ip) => ({ address: ip, is_private: false }));
  };

  const removeCarriageReturn = (lines) => {
    const cleanedLines = lines.map((line) => line.replace(/\r/g, ""));
    return cleanedLines;
  };

  const convertToCustomFormat = (data) => {
    const lines = data.split("\n"); // Her bir satırı ayırma
    let linesSplice = lines.splice(1, lines.length - 1);
    let filteredLines = linesSplice.filter(
      (item) => item.trim() !== "" && item.trim() !== "\r"
    );

    const convertedList = filteredLines.map((line) => {
      const [address, isPrivate] = line.split(";"); // Satırdaki öğeleri ayırma

      return {
        address: address.trim(), // IP adresi
        is_private: isPrivate === "internal\r" ? true : false, // Boolean değer kontrolü
      };
    });
    return convertedList;
  };

  const handleFileRead = (e) => {
    const content = e.target.result;
    const result = convertToCustomFormat(content);
    sendHostsAsset(result);
  };

  const handleFileReadDrop = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      const result = convertToCustomFormat(content);
      sendHostsAsset(result);
    };

    reader.readAsText(file);
  };

  const handleFileChosen = (file) => {
    const reader = new FileReader();
    reader.onload = handleFileRead;
    reader.readAsText(file);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleFileChosen(selectedFile);
      fileInputRef.current.value = "";
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileReadDrop(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const downloadExampleCSV = () => {
    // Örnek CSV dosyasının URL'sini oluştur
    const downloadUrl = "/target_address_sample_file.csv";

    // Dosyayı indirme işlemi için bir link oluştur
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "target_address_sample_file.csv"; // İndirilen dosyanın adı

    // Oluşturulan linki tıkla ve dosyayı indir
    link.click();
  };
  return (
    <div className="col-xxl-12  ">
      {/* Add Public Address */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={handleGenerateIPsPublic}
        text="Are you sure you want to add public address?"
        loadingAction={true}
        isLoading={addPublicLoading}
      />
      {/* Add Private Address */}
      <ConfirmModal
        modal_standard={modal_standard6}
        tog_standard={tog_standard6}
        setmodal_standard={setmodal_standard6}
        targetFunction={handleGenerateIPsPrivate}
        text="Are you sure you want to add private address?"
        loadingAction={true}
        isLoading={addPrivateLoading}
      />
      {/* Remove All Host */}
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={removeHostsAsset}
        text="Are you sure you want to remove all hosts?"
        loadingAction={true}
        isLoading={deleteLoading}
      />
      {/* Update Module */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={updateHosts}
        text="Are you sure you want to update module?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      {/* Remove Module */}
      <ConfirmModal
        modal_standard={modal_standard4}
        tog_standard={tog_standard4}
        setmodal_standard={setmodal_standard4}
        targetFunction={removeModule}
        text="Are you sure you want to remove module?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      {/* Add Task Modal */}
      {hosts && (
        <HostsAddTask
          modal_standard={modal_standard}
          tog_standard={tog_standard}
          setmodal_standard={setmodal_standard}
          userID={datas.user_id}
          moduleID={datas.id}
          allAssets={hosts}
        />
      )}
      <Card className="border-primary special-card">
        <CardHeader className="d-flex justify-content-between   ">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-web me-3"></i>
            {datas.text}
          </h5>
          <div className="d-flex ">
            <label className="form-label me-1 ">Active</label>
            <div className="square-switch">
              <input
                type="checkbox"
                id="is_active_host"
                name="is_active_host"
                switch="primary"
                defaultChecked={is_active}
                autoComplete="off"
                onChange={(e) => set_is_active(e.target.checked)}
              />
              <label
                htmlFor="is_active_host"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="col-12 mb-1 ">
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              className="file-upload"
            >
              <span></span>
              <label htmlFor="file-input" className="file-label">
                <input
                  ref={fileInputRef}
                  id="file-input"
                  type="file"
                  className="file-input"
                  accept=".csv"
                  onChange={handleChange}
                />
                <div className="d-flex flex-row font-size-16 mt-1 ">
                  <i className="mdi mdi-file-upload" />{" "}
                  <div className="d-flex flex-row ">
                    <span>Drag your designs here or</span>
                    <span className="file-button ms-1 ">click to upload</span>
                  </div>
                </div>
              </label>
              <button
                onClick={downloadExampleCSV}
                className="btn btn-success btn-sm me-1  "
              >
                Example CSV Download
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-3">
              <label>New IP or Subnet</label>
              <div className="input-group mb-3">
                <input
                  type="address"
                  className="form-control"
                  id="systemscan_newsubnetinput"
                  placeholder="Ex: 192.168.1.0/24 or 192.168.3.16-192.168.3.18 or 10.0.0.1 or 10.0.0.2 ..."
                  autoComplete="off"
                  value={hostInput}
                  onChange={handleTagInputChange}
                />
                <div className="btn-group" role="group">
                  <Dropdown
                    isOpen={btnprimary1}
                    toggle={() => setBtnprimary1(!btnprimary1)}
                  >
                    <DropdownToggle tag="button" className="btn btn-primary">
                      Actions <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={tog_standard5}>
                        <i className="mdi mdi-weather-cloudy-alert text-warning me-2"></i>
                        Public Address
                      </DropdownItem>
                      <DropdownItem onClick={tog_standard6}>
                        <i className="mdi mdi-ip-network-outline text-info me-2"></i>
                        Private Address
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center ">
              <span>Host List</span>
              <button
                onClick={() => {
                  tog_standard2();
                }}
                className="btn btn-outline-danger btn-sm  "
              >
                Remove All
              </button>
            </div>
            <div
              className="border border-1 border-primary d-flex flex-wrap gap-1  p-1  "
              style={{
                maxHeight: "280px",
                overflowY: "scroll",
                minHeight: "100px",
              }}
            >
              <AnimatePresence>
                {hosts?.map((tag, index) => (
                  <motion.div
                    initial={{ height: "auto", opacity: 1 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    key={tag.address}
                    className=""
                  >
                    <Badge
                      style={{ maxHeight: "25px" }}
                      className={`fs-6 w-100   tag  bg-${
                        tag.is_private === false ? "danger" : "primary"
                      } mr-1`}
                      title={`ID=${tag.id} ${
                        tag.is_private === true ? "Private" : "Public"
                      } ${tag.address}`}
                    >
                      {tag.is_private === true ? (
                        <i className=" float-start mdi mdi-ip-network-outline address-white me-1" />
                      ) : (
                        <i className="float-start mdi mdi-weather-cloudy-alert address-white me-1" />
                      )}

                      <span className="mx-1">{tag.address}</span>
                      <span
                        className={`float-end  remove-tag  badge-danger me-1   ${
                          tag.is_private === false
                            ? "public-tag"
                            : "private-tag"
                        }`}
                        onClick={() => handleRemoveTag(tag, index)}
                      >
                        X
                      </span>
                    </Badge>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </div>
          <div className="col-md-12 mt-2 ">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    name="ip_address"
                    min={1}
                    step={1}
                    defaultValue={asset_count}
                    autoComplete="off"
                    onChange={(e) => set_asset_count(e.target.value)}
                  />
                  <label>Number Of IP Address</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col border-end d-flex justify-content-between align-items-end">
            <button
              onClick={() => {
                setViewTickets(!viewTickets);
              }}
              className="btn btn-outline-info "
            >
              <i className="mdi mdi-view-grid label-icon  me-1 " />
              {tickets && viewTickets ? "Close Tickets" : "View Tickets"}
            </button>
            <div className="d-flex flex-wrap justify-content-center  gap-2 ">
              <button
                className="btn btn-outline-primary"
                id="updateWebAppBtn"
                type="button"
                onClick={tog_standard3}
              >
                <i className="bx bx-check-double label-icon" /> Update
              </button>
              <button
                className="btn btn-outline-danger removeModule"
                data-mod="WEB_APPLICATION"
                type="button"
                onClick={() => {
                  tog_standard4();
                }}
              >
                <i className="bx bx-block label-icon" /> Remove
              </button>
            </div>
            <button
              onClick={() => {
                tog_standard();
              }}
              className="btn btn-outline-success "
            >
              <i className="mdi mdi-view-grid label-icon  me-1 " />
              Add Task
            </button>
          </div>
          <AnimatePresence>
            {viewTickets && tickets && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 0, opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="mt-4"
              >
                <HostsTickets tickets={tickets} />
              </motion.div>
            )}
          </AnimatePresence>
        </CardBody>
      </Card>
    </div>
  );
};

export default Hosts;
