import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { toastSuccess } from "../../../../../common/utils/commonFunctions";
import step1 from "../../../../../assets/images/addNewApplianceImages/step1.svg";
import step2 from "../../../../../assets/images/addNewApplianceImages/step2.svg";
import step5 from "../../../../../assets/images/addNewApplianceImages/step5.svg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const AddNewApplianceModal = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  newApplianceData,
  getAppliance,
}) => {
  const [url, setUrl] = useState(
    newApplianceData?.platforms[0]?.link + newApplianceData.configuration.uuid
  );
  const [step, setStep] = useState(1);
  const [isFits, setisFits] = useState(false);
  const [lightBoxImage, setLightBoxImage] = useState();

  const buttons = Array(6).fill(0);

  useEffect(() => {
    if (!modal_standard) {
      setStep(1);
      setUrl(
        newApplianceData?.platforms[0]?.link +
          newApplianceData.configuration.uuid
      );
    }
  }, [modal_standard]);

  return (
    <div>
      {isFits ? (
        <Lightbox
          mainSrc={lightBoxImage}
          enableZoom={true}
          onCloseRequest={() => {
            setisFits(!isFits);
          }}
        />
      ) : null}
      <Modal
        size="lg"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New Appliance
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
              getAppliance();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-between new-appliance-modal">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "560px" }}
          >
            {step === 1 && (
              <React.Fragment>
                <div className="mb-3 row w-100 justify-content-center align-items-center">
                  <div className="form-group col-lg-9 col-6">
                    <select
                      onChange={(e) =>
                        setUrl(
                          e.target.value + newApplianceData.configuration.uuid
                        )
                      }
                      className="form-select"
                    >
                      {newApplianceData.platforms.map(
                        (platform, platformIndex) => {
                          return (
                            <option key={platformIndex} value={platform.link}>
                              {platform.text}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div className="col-lg-3 col-6">
                    <a className="" href={url} target="_blank" rel="noreferrer">
                      <button
                        type="button"
                        className="btn btn-success waves-effect btn-label waves-light   w-100 "
                      >
                        <i className="mdi mdi-download me-3 label-icon"></i>
                        Download
                      </button>
                    </a>
                  </div>
                </div>
                <div className="mb-1">
                  <div className="row  justify-content-around">
                    <div className="d-flex flex-column col-lg-7 ">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <h4 className="mb-2">Deploy</h4>
                        <span className="fw-medium text-center mt-1">
                          Download the device template suitable for the
                          virtualization platform you are using and import it
                          into the virtualization environment.
                        </span>
                      </div>
                      {newApplianceData?.checksums?.map((item) => (
                        <div
                          key={item.text}
                          className="d-flex flex-column font-size-12 align-items-center mt-2"
                        >
                          <h6>{item.text}</h6>
                          <i className="mdi mdi-arrow-down" />
                          <span className="fw-medium text-center">
                            {item.value}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex flex-column col-lg-5 justify-content-center align-items-center">
                      <img
                        src={step1}
                        alt="step1"
                        className="col-lg-6  col-8 rounded-2"
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {step === 2 && (
              <React.Fragment>
                <div className="mb-1">
                  <div className="row  justify-content-around w-100">
                    <div className="d-flex flex-column col-md-6 justify-content-center align-items-center">
                      <img
                        src={step2}
                        alt="step2"
                        className="col-lg-6 col-8 rounded-2"
                      />
                    </div>
                    <div className="d-flex flex-column col-md-6 justify-content-center align-items-center">
                      <h4 className="mb-2">Firewall Premissions</h4>
                      <span className="fw-medium text-center mt-1">
                        -Allow access to the appliance <br />
                        {newApplianceData.permissions.map((permission) => (
                          <React.Fragment key={permission}>
                            <span>{permission}</span>
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {step === 3 && (
              <React.Fragment>
                <div className="mb-1">
                  <div className="row  justify-content-around">
                    <div className="d-flex flex-column col-lg-6 justify-content-center align-items-center">
                      <h4 className="mb-2">Configure Network</h4>
                      <span className="fw-medium text-center mt-1">
                        Go to the Set Up Network menu. Configure the network
                        interface to enable the appliance to access the
                        Pentestbx cloud. When the appliance accesses the
                        PentestBX, the pointer on the top right will be green.
                      </span>
                    </div>
                    <div className="d-flex flex-column col-lg-6 justify-content-center align-items-center">
                      <video autoPlay muted loop id="background-video">
                        <source src="/network_settings.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {step === 4 && (
              <React.Fragment>
                <div className="d-flex  align-items-center justify-content-center mb-2 mt-4 mt-lg-0 ">
                  <span className="me-2 fw-bold font-size-16">Pin Code :</span>
                  <span className="fw-bold font-size-20  border border-1 border-primary p-1 rounded-3">
                    {newApplianceData.pin_code}
                  </span>
                  <button
                    type="button"
                    className="btn btn-success waves-effect btn-label waves-light ms-2    "
                    onClick={() => {
                      var textField = document.createElement("textarea");
                      textField.innerText = newApplianceData.pin_code;
                      document.body.appendChild(textField);
                      textField.select();
                      document.execCommand("copy");
                      textField.remove();
                      toastSuccess(`${newApplianceData.pin_code} copied`);
                    }}
                  >
                    <i className="mdi mdi-content-copy me-3 label-icon"></i>
                    Copy
                  </button>
                </div>
                <div className="mb-1">
                  <div className="row  justify-content-around">
                    <div className="d-flex flex-column col-lg-6 justify-content-center align-items-center">
                      <video autoPlay muted loop id="background-video">
                        <source src="/sync.mp4" type="video/mp4" />
                      </video>
                    </div>
                    <div className="d-flex flex-column col-lg-6 justify-content-center align-items-center">
                      <h4 className="mb-2 mt-lg-0 mt-2">Activate Appliance</h4>
                      <span className="fw-medium text-center mt-1">
                        Go to the Sync Cloud menu. Enter the pin code defined
                        for you above in the relevant field and wait. The device
                        will reboot when successful.
                      </span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {step === 5 && (
              <React.Fragment>
                <div className="mb-1">
                  <div className="row  justify-content-around">
                    <div className="d-flex flex-column col-lg-6 justify-content-center align-items-center">
                      <h4 className="mb-1">Final Checks</h4>
                      <span className="fw-medium text-center  mt-1">
                        If the first icon on the top right is green (PentestBX
                        access) and the second icon is colored (purple, orange
                        and blue), the installation is successfully completed
                        (synchronization).
                      </span>
                    </div>
                    <div className="d-flex flex-column col-lg-6 justify-content-center align-items-center">
                      <img
                        src={step5}
                        alt="step5"
                        className=" col-lg-12   col-9 rounded-2 cursor-pointer"
                        style={{ objectFit: "contain", height: "100%" }}
                        onClick={() => {
                          setisFits(!isFits);
                          setLightBoxImage(step5);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {step === 6 && (
              <React.Fragment>
                <div>
                  <h5>Please Note: </h5>
                  <span className="fw-medium text-center mt-1">
                    If you are not in a situation as mentioned in the final
                    checks step, please check the Appliance's internet
                    connection and make sure that the permissions specified
                    before the installation phase are defined. If you need
                    further assistance with this issue, please click the report
                    issue button to get help.
                  </span>
                </div>
                <button
                  type="button"
                  className="btn btn-primary waves-effect btn-label waves-light   w-100 mt-4 "
                  onClick={() => {
                    tog_standard();
                    getAppliance();
                  }}
                >
                  <i className="mdi mdi-target me-3 label-icon"></i>
                  Finish
                </button>
              </React.Fragment>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5">
            <svg
              fill={step > 1 ? "#8f1860" : "#fff"}
              className="cursor-pointer"
              height="96"
              viewBox="0 0 24 24"
              width="96"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                if (step > 1) {
                  setStep((prew) => Number(prew - 1));
                }
              }}
            >
              <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
              <path d="M0-.5h24v24H0z" fill="none" />
            </svg>
            {buttons.map((_, index) => (
              <div
                onClick={() => setStep(Number(index + 1))}
                key={index}
                className={`pagination-button bg-primary ${
                  index + 1 === step ? "active" : ""
                }`}
              ></div>
            ))}
            <svg
              className="cursor-pointer"
              height="96"
              viewBox="0 0 24 24"
              width="96"
              xmlns="http://www.w3.org/2000/svg"
              fill={step < 6 ? "#8f1860" : "#fff"}
              onClick={() => {
                if (step < 6) {
                  setStep((prew) => Number(prew + 1));
                }
              }}
            >
              <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
              <path d="M0-.25h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddNewApplianceModal;
