import React, { useEffect, useState } from "react";
import { CardHeader, CardBody, Container } from "reactstrap";
import { patch } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import continents from "../../../common/utils/continents.json";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import Rating from "react-rating";
import {
  formatDateFlatPickr,
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";
import Flatpickr from "react-flatpickr";

const DistributorsDetail = ({ distributorData }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [authentication, set_authentication] = useState(
    distributorData.authentication
  );
  const [organization_name, set_organization_name] = useState(
    distributorData.organization_name
  );
  const [phone_number, set_phone_number] = useState(
    distributorData.phone_number
  );
  const [email, set_email] = useState(distributorData.email);
  const [region, set_region] = useState(distributorData.region);
  const [country, set_country] = useState(distributorData.country);
  const [state, set_state] = useState(distributorData.state);
  const [city, set_city] = useState(distributorData.city);
  const [address, set_address] = useState(distributorData.address);
  const [rank, set_rank] = useState(distributorData.rank);
  const [enable, set_enable] = useState(distributorData.enable);
  const [subscription_renewal, set_subscription_renewal] = useState(
    distributorData.subscription_renewal
  );
  const [date_joined, set_date_joined] = useState(distributorData.date_joined);

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, set_addLoading] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const options = { year: "numeric", month: "long", day: "numeric" };
  const subscriptionRenewal = new Date(distributorData?.subscription_renewal);
  const formattedSubscriptionRenewal = subscriptionRenewal.toLocaleDateString(
    "en-US",
    options
  );

  const joinedDate = new Date(distributorData?.date_joined);
  const formattedJoinedDate = joinedDate.toLocaleDateString("en-US", options);
  const changeTime = (e) => {
    set_subscription_renewal(formatDateFlatPickr(e[0]));
  };

  const handleSubmit = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      id: distributorData.id,
      email,
      phone_number,
      organization_name,
      authentication: authentication === null ? "None" : authentication,
      region,
      country,
      state,
      city,
      address,
      rank,
      enable,
      subscription_renewal,
      date_joined,
    };
    try {
      let result = await patch("/admin/distributor", data);
      if (result?.success) {
        toastSuccess(result?.message || "Distributor Updated");
        tog_standard5();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };
  useEffect(() => {
    GetCountries().then((result) => {
      let distributorCountry = result.find(
        (item) => item.name === distributorData.country
      );
      setCountryid(distributorCountry.id);
      setCountriesList(result);

      GetState(distributorCountry.id).then((stateResult) => {
        let distributorState = stateResult.find(
          (item) => item.name === distributorData.state
        );
        setStateid(distributorState.id);
        setStateList(stateResult);
        GetCity(distributorCountry.id, distributorState.id).then(
          (cityResult) => {
            setCityList(cityResult);
          }
        );
      });
    });
  }, []);
  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={handleSubmit}
        text="Are you sure you want to update distributor?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <div className="page-content">
        <Container fluid>
          <div className=" special-card">
            <CardHeader
              // style={{ backgroundColor: "#f8f8f8" }}
              className="d-flex justify-content-between   "
            >
              <h4 className="card-title text-primary ">Update Distributor</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Account Email :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-email-multiple-outline" />
                        </span>
                        <input
                          disabled
                          type="email"
                          className="form-control"
                          style={{ width: "80%" }}
                          name="email"
                          placeholder="Type Account Email"
                          defaultValue={email}
                          onChange={(e) => set_email(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Phone Number :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-phone" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "80%" }}
                          name="phone_number"
                          placeholder="Type Phone Number"
                          defaultValue={phone_number}
                          onChange={(e) => set_phone_number(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Company Name :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-office-building-outline" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "80%" }}
                          name="organization_name"
                          defaultValue={organization_name}
                          onChange={(e) =>
                            set_organization_name(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Region :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-city-variant" />
                        </span>
                        <select
                          className="form-select"
                          style={{ width: "80%" }}
                          name="region"
                          id="region"
                          onChange={(e) => set_region(e.target.value)}
                          defaultValue={region}
                        >
                          {continents.map((continentName, continentKey) => {
                            return (
                              <option
                                value={continentName}
                                key={continentName + continentKey}
                              >
                                {continentName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Country :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-flag" />
                        </span>
                        <select
                          onChange={(e) => {
                            let countryValues = JSON.parse(e.target.value);
                            set_country(countryValues.name);
                            setCountryid(countryValues.id);
                            GetState(countryValues.id).then((result) => {
                              setStateList(result);
                              setStateid(result[0].id);
                              set_state(result[0].name);
                              GetCity(countryValues.id, result[0].id).then(
                                (resultCity) => {
                                  setCityList(resultCity);
                                  setCityid(resultCity[0].id);
                                  set_city(resultCity[0].name);
                                }
                              );
                            });
                          }}
                          value={JSON.stringify(
                            countriesList.find((item) => item.name === country)
                          )}
                          className="form-select"
                          style={{ width: "80%" }}
                          name="country"
                          id="country"
                        >
                          {countriesList.map((item, index) => (
                            <option
                              key={item + index}
                              value={JSON.stringify(item)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">State/Province :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-home-group" />
                        </span>
                        <select
                          onChange={(e) => {
                            let stateValues = JSON.parse(e.target.value);
                            set_state(stateValues.name);
                            setStateid(stateValues.id);
                            GetCity(countryid, stateValues.id).then(
                              (result) => {
                                setCityList(result);
                                setCityid(result[0].id);
                                set_city(result[0].name);
                              }
                            );
                          }}
                          value={JSON.stringify(
                            stateList.find((item) => item.name === state)
                          )}
                          className="form-select"
                          style={{ width: "80%" }}
                          name="state"
                          id="state"
                        >
                          {stateList.map((item, index) => (
                            <option
                              key={item + index}
                              value={JSON.stringify(item)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">City/District :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-city" />
                        </span>
                        <select
                          onChange={(e) => {
                            let cityValues = JSON.parse(e.target.value); //here you will get full state object.
                            setCityid(cityValues.id);
                            set_city(cityValues.name);
                          }}
                          value={JSON.stringify(
                            cityList.find((item) => item.name === city)
                          )}
                          className="form-select"
                          style={{ width: "80%" }}
                          name="city"
                          id="city"
                        >
                          {cityList.map((item, index) => (
                            <option
                              key={item + index}
                              value={JSON.stringify(item)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Address :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-map" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "80%" }}
                          name="address"
                          defaultValue={address}
                          onChange={(e) => set_address(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Joined Date :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-calendar-range" />
                        </span>
                        <Flatpickr
                          name="expiration_date"
                          className="form-control d-block"
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "F j, Y",
                          }}
                          defaultValue={formattedJoinedDate}
                          // onChange={changeTime}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Subscription Renewal :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-airplane-clock" />
                        </span>
                        <Flatpickr
                          name="expiration_date"
                          className="form-control d-block bg-flatpicker  cursor-pointer "
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "F j, Y",
                          }}
                          defaultValue={formattedSubscriptionRenewal}
                          onChange={changeTime}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Rank :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-star-box" />
                        </span>
                        <Rating
                          className="form-control"
                          emptySymbol="mdi mdi-star "
                          fullSymbol="mdi mdi-star text-warning "
                          start={0}
                          stop={5}
                          initialRating={rank}
                          onChange={(e) => set_rank(Number(e))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Authentication :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <div className="btn-group btn-group-sm   " role="group">
                          <input
                            type="radio"
                            className="btn-check"
                            name="authentication"
                            id="auth2faNone1"
                            defaultValue="None"
                            autoComplete="off"
                            defaultChecked={
                              authentication === "None" ? true : false
                            }
                            onChange={(e) => set_authentication(e.target.value)}
                          />
                          <label
                            className="btn btn-outline-primary"
                            htmlFor="auth2faNone1"
                          >
                            None
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            name="authentication"
                            id="auth2faSms1"
                            defaultValue="SMS"
                            autoComplete="off"
                            defaultChecked={
                              authentication === "SMS" ? true : false
                            }
                            onChange={(e) => set_authentication(e.target.value)}
                          />
                          <label
                            className="btn btn-outline-primary"
                            htmlFor="auth2faSms1"
                          >
                            SMS
                          </label>
                          <input
                            disabled
                            type="radio"
                            className="btn-check"
                            name="authentication"
                            id="auth2faQr1"
                            defaultValue="QR"
                            autoComplete="off"
                            defaultChecked={
                              authentication === "QR" ? true : false
                            }
                            onChange={(e) => set_authentication(e.target.value)}
                          />
                          <label
                            className="btn btn-outline-primary"
                            htmlFor="auth2faQr1"
                          >
                            QR
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Active :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="square-switch">
                        <input
                          type="checkbox"
                          id="enable"
                          name="enable"
                          is_demo="enable"
                          switch="primary"
                          defaultChecked={enable}
                          autoComplete="off"
                          onChange={(e) => set_enable(e.target.checked)}
                        />
                        <label
                          htmlFor="enable"
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex justify-content-center">
                  <button
                    onClick={tog_standard5}
                    type="button"
                    id="save_updatebtn"
                    className="btn btn-lg btn-primary"
                  >
                    <i className="mdi mdi-book-edit-outline me-3" />
                    Update Distributor
                  </button>
                </div>
              </form>
            </CardBody>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DistributorsDetail;
