import React, { useEffect, useRef, useState } from "react";
import { Modal, Badge } from "reactstrap";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import Flatpickr from "react-flatpickr";
import { motion, AnimatePresence } from "framer-motion";
import { post, put } from "../../../../../helpers/api_helper";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const CyberWatchAddTask = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  userID,
  moduleID,
  allAssets,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  // Add Tasks
  const [programme, set_programme] = useState("schedule");
  const [type, set_type] = useState("all");
  const [schedule_date, set_schedule_date] = useState();
  const [selectedDay, setSelectedDay] = useState();

  const [addTaskLoading, setAddTaskLoading] = useState(false);

  const [assets, setAssets] = useState(allAssets);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [allFilteredAssets, setAllFilteredAssets] = useState([]);
  const [searchInputValue, set_searchInputValue] = useState("");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const [modal_standard2, setmodal_standard2] = useState(false);

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  useEffect(() => {
    const currectDate = new Date();
    const day = currectDate.getDate();
    const month = currectDate.getMonth();
    const year = currectDate.getFullYear();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    setSelectedDay(modifiedDate);
    set_schedule_date(modifiedDate.toISOString());
  }, []);

  useEffect(() => {
    const missingAssets = findMissingAssets(allAssets, assets);
    setFilteredAssets(missingAssets);
    setAllFilteredAssets(missingAssets);
  }, [assets]);

  useEffect(() => {
    if (!modal_standard) {
      set_type("all");
      set_programme("schedule");
      setAssets(allAssets);
    }
  }, [modal_standard]);

  useEffect(() => {
    setAssets(allAssets);
  }, [allAssets]);

  const findMissingAssets = (allAssets, assets) => {
    const assetAddresses = assets?.map((asset) => asset?.address);

    const missingAssets = allAssets?.filter(
      (asset) => !assetAddresses?.includes(asset?.address)
    );

    return missingAssets;
  };

  const handleInputFocus = () => {
    if (!isDropdownOpen && !isOpen) {
      setIsDropdownOpen(true);
      setIsOpen(true);
    }
  };

  const handleInputBlur = () => {
    if (isDropdownOpen && isOpen) {
      setTimeout(() => {
        setIsDropdownOpen(false);
        setIsOpen(false);
        set_searchInputValue("");
      }, 100);
    }
  };

  const handleOutsideClick = (event) => {
    if (!event.target.classList.contains("exclude-span")) {
      inputRef.current.focus();
    }
  };

  const addAssetAddress = (item) => {
    let tempArray = [...assets, item];
    setAssets(tempArray);
    set_searchInputValue("");
  };

  const filterAddressesByNumber = (number) => {
    const filteredAddresses = allFilteredAssets?.filter((address) => {
      return address?.address?.includes(number.toString());
    });

    return filteredAddresses;
  };

  const handleChangeSearch = (e) => {
    set_searchInputValue(e.target.value);
    let result = filterAddressesByNumber(e.target.value);
    setFilteredAssets(result);
  };

  const handleRemoveAddress = async (tag, index) => {
    const updatedTags = [...assets];
    updatedTags.splice(index, 1);
    setAssets(updatedTags);
  };

  const changeTime = (e) => {
    const originalDate = new Date(e[0]);
    const isoDateString = originalDate.toISOString();
    set_schedule_date(isoDateString);
  };

  const addTask = async () => {
    paceFunction(true);
    setAddTaskLoading(true);
    let taskData;
    if (programme === "schedule") {
      if (type === "all") {
        taskData = {
          id: userID,
          module_id: moduleID,
          date: schedule_date,
          type,
        };
      } else {
        let customAssets = assets.map((item) => item.id);
        taskData = {
          id: userID,
          module_id: moduleID,
          date: schedule_date,
          type,
          assets: customAssets,
        };
      }
      try {
        let result = await post("/admin/customer/task", taskData);
        if (result.success) toastSuccess(result.message || "Task Added");
        tog_standard();
        tog_standard2();
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      // On Time
      if (type === "all") {
        taskData = {
          id: userID,
          module_id: moduleID,
          type,
        };
      } else {
        let customAssets = assets.map((item) => item.id);
        taskData = {
          id: userID,
          module_id: moduleID,
          type,
          assets: customAssets,
        };
      }
      try {
        let result = await put("/admin/customer/task", taskData);
        if (result.success) toastSuccess(result.message || "Task Added");
        tog_standard();
        tog_standard2();
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    }
    setAddTaskLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Add Task */}
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={addTask}
        text="Are you sure you want to add task?"
        loadingAction={true}
        isLoading={addTaskLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Task
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            className="btn-group d-flex justify-content-center"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio5"
              value="schedule"
              defaultChecked={programme === "schedule" ? true : false}
              onChange={(e) => set_programme(e.target.value)}
            />
            <label className="btn btn-outline-primary" htmlFor="btnradio5">
              Schedule
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio6"
              value="onTime"
              defaultChecked={programme === "onTime" ? true : false}
              onChange={(e) => set_programme(e.target.value)}
            />
            <label className="btn btn-outline-primary" htmlFor="btnradio6">
              On Time
            </label>
          </div>
          <div className="form-group row mt-3 justify-content-center">
            <div className="col-12 ">
              <select
                className="form-select border-primary "
                onChange={(e) => set_type(e.target.value)}
              >
                <option value="all">ALL</option>
                <option value="custom">CUSTOM</option>
              </select>
            </div>
          </div>
          {programme === "schedule" && (
            <div className="col-12 mt-2">
              <div className="form-floating mb-3">
                <Flatpickr
                  name="expiration_date"
                  className="form-control d-block"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y H:i",
                    dateFormat: "F j, Y H:i",
                    enableTime: true,
                    defaultDate: selectedDay,
                  }}
                  onChange={changeTime}
                />
                <label>Trigger Date</label>
              </div>
            </div>
          )}
          {type === "custom" && (
            <div className="form-group my-3">
              <div className="d-flex justify-content-between align-items-center ">
                <label>Assets ({assets.length})</label>
                <div>
                  <button
                    type="button"
                    data-tag-select="ip_addressesstags"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                      setAssets(allAssets);
                    }}
                  >
                    Select All
                  </button>
                  <button
                    type="button"
                    data-tag-unselect="ip_addressesstags"
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => {
                      setAssets([]);
                    }}
                  >
                    Unselect All
                  </button>
                </div>
              </div>
              <div
                className="border border-1 border-primary d-flex flex-wrap gap-1   p-1 rounded-3   "
                style={{
                  maxHeight: "280px",
                  overflowY: "overlay",
                  minHeight: "100px",
                }}
                onClick={handleOutsideClick}
              >
                <AnimatePresence>
                  {assets?.map((tag, index) => (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      key={tag?.address}
                      className="exclude-span  "
                    >
                      <Badge
                        style={{ maxHeight: "25px" }}
                        className={`fs-6 w-100   tag  bg-${
                          tag?.is_private === false ? "danger" : "primary"
                        } mr-1`}
                        title={` ${
                          tag?.is_private === true ? "Private" : "Public"
                        } ${tag.address}`}
                      >
                        {tag?.is_private === true ? (
                          <i className="float-start mdi mdi-ip-network-outline address-white me-1" />
                        ) : (
                          <i className="float-start mdi mdi-weather-cloudy-alert address-white me-1" />
                        )}

                        <span className="mx-1">{tag?.address}</span>
                        <span
                          className={`float-end exclude-span remove-tag  badge-danger me-1   ${
                            tag?.is_private === false
                              ? "public-tag"
                              : "private-tag"
                          }`}
                          onClick={() => handleRemoveAddress(tag, index)}
                        >
                          X
                        </span>
                      </Badge>
                    </motion.div>
                  ))}
                </AnimatePresence>
                <div className="outside-area">
                  <input
                    style={{ border: "none", outline: "none" }}
                    className="exclude-span input-dark"
                    type="text"
                    value={searchInputValue}
                    ref={inputRef}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onChange={handleChangeSearch}
                  />
                </div>
              </div>
              <AnimatePresence>
                {isDropdownOpen && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`dropdown-host ${isOpen ? "open" : ""}`}
                    ref={dropdownRef}
                  >
                    <ul
                      style={{
                        top: "-15px",
                        maxHeight: "257px",
                        overflowY: "overlay",
                      }}
                      className="options-host"
                    >
                      {filteredAssets?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => addAssetAddress(item)}
                          className="fs-6"
                        >
                          {item?.address}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
          >
            Cancel
          </button>
          <button
            onClick={() => {
              tog_standard2();
            }}
            type="button"
            className="btn btn-primary "
          >
            Add Task
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CyberWatchAddTask;
